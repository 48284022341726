import React from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "./supabaseClient";
import UserProgress from "./UserProgress";
import { Button } from "./ui/button";
import { BarChart2, User, Menu, ClipboardList } from "lucide-react";
import AdminActionsDropdown from "./AdminActionsDropdown";
import { Tabs, TabsList, TabsTrigger } from "./ui/tabs";

const MainNavigation = ({
  session,
  isUserDataLoading,
  streak,
  xp,
  showStatistics,
  isAdminUser,
  activeView,
  setActiveView,
  setSelectedTest,
  onLevelUp,
  assignedTasksCount,
  setShowAssignedTasks,
  selectedProblem,
  setSelectedProblem, // Add this prop
  setShowAddProblem,
  toggleTagManager,
  setShowTestEditor,
  setShowAssignTask,
  setShowUserProblemStatus,
  setWantLogIn,
  setShowStatistics,
  setIsMobileMenuOpen,
}) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setIsMobileMenuOpen(false);
    setWantLogIn(false);
  };

  const handleLogin = () => {
    setWantLogIn(true);
    navigate("/login");
  };

  const handleToggleStatistics = () => {
    setShowStatistics(!showStatistics);
    setSelectedProblem(null);
    setIsMobileMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const handleLogoClick = () => {
    setActiveView("all");
    setSelectedTest(null);
    if (showStatistics) {
      setShowStatistics(false);
    }
    navigate("/");
  };

  return (
    <nav className="mb-8">
      <div className="flex justify-between items-center">
        <div
          className="flex items-center cursor-pointer"
          onClick={handleLogoClick}
        >
          <img
            src="/matpat.png"
            alt="Matika od Patrika Logo"
            className="max-w-full h-auto w-20 md:w-20"
          />
        </div>

        <div className="flex items-center space-x-4">
          {session && !isUserDataLoading && (
            <UserProgress streak={streak} xp={xp} onLevelUp={onLevelUp} />
          )}

          {session && !isAdminUser && (
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setShowAssignedTasks(true)}
              className="hidden md:flex items-center"
            >
              <ClipboardList className="h-5 w-5 mr-1" />
              <span>Moje úlohy</span>
              {assignedTasksCount > 0 && (
                <span className="ml-2 bg-red-100 text-red-800 text-xs font-semibold px-2 py-0.5 rounded-full">
                  {assignedTasksCount}
                </span>
              )}
            </Button>
          )}

          {session && (
            <Button
              variant="ghost"
              size="sm"
              onClick={handleToggleStatistics}
              className="hidden md:flex items-center"
            >
              <BarChart2 className="h-5 w-5 mr-1" />
              <span>Štatistiky</span>
            </Button>
          )}

          {isAdminUser && (
            <AdminActionsDropdown
              onShowStatistics={handleToggleStatistics}
              onAddProblem={() => setShowAddProblem(true)}
              onManageTags={toggleTagManager}
              onShowCalendar={() => navigate("/lekcie")}
              onManageTests={() => setShowTestEditor(true)}
              onAssignTask={() => setShowAssignTask(true)}
              onShowUserStatus={() => setShowUserProblemStatus(true)}
            />
          )}

          <div className="flex items-center justify-between">
            {!selectedProblem && !showStatistics && (
              <div className="flex-1 max-w-2xl">
                <Tabs defaultValue="all" className="w-full">
                  <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger
                      value="all"
                      onClick={() => {
                        setActiveView("all");
                        setSelectedTest(null);
                      }}
                    >
                      Všetky príklady
                    </TabsTrigger>
                    <TabsTrigger
                      value="test"
                      onClick={() => {
                        setActiveView("test");
                        setSelectedTest(null);
                      }}
                    >
                      Testy
                    </TabsTrigger>
                  </TabsList>
                </Tabs>
              </div>
            )}

            {session ? (
              <Button
                variant="ghost"
                size="sm"
                onClick={handleLogout}
                className="hidden md:flex ml-4 items-center"
              >
                <User className="h-5 w-5 mr-1" />
                <span>Odhlásiť sa</span>
              </Button>
            ) : (
              <Button
                variant="ghost"
                size="sm"
                onClick={handleLogin}
                className="hidden md:flex ml-4 items-center"
              >
                <User className="h-5 w-5 mr-1" />
                <span>Prihlásiť sa</span>
              </Button>
            )}
          </div>

          <Button
            variant="ghost"
            size="sm"
            onClick={toggleMobileMenu}
            className="md:hidden"
          >
            <Menu className="h-5 w-5" />
          </Button>
        </div>
      </div>
    </nav>
  );
};

export default MainNavigation;
