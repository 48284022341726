// src/components/MixedContent.js
import React from "react";
import { InlineMath, BlockMath } from "react-katex";

const MixedContent = ({ content }) => {
  const parts = content.split(/(\$.*?\$)/g);

  return (
    <span className="math-content">
      {parts.map((part, index) => {
        if (part.startsWith("$") && part.endsWith("$")) {
          const mathExpression = part.slice(1, -1);
          return (
            <InlineMath
              key={index}
              math={mathExpression}
              // Add custom styling for mobile
              renderError={(error) => {
                console.error(error);
                return error.name;
              }}
            />
          );
        }
        return <span key={index} dangerouslySetInnerHTML={{ __html: part }} />;
      })}
    </span>
  );
};

export default MixedContent;
